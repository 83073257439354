// src/pages/Home.tsx
import React from "react";
import Button from "../components/Button";
import QRCode from "../components/QRCode";
import ImageCarousel from "../components/ImageCarousel";
import MetaTags from "../components/MetaTags";
import { Helmet } from "react-helmet-async";

const Home: React.FC = () => {
  return (
    <div className="min-h-screen bg-black text-white font-inter flex items-center justify-center">
      <MetaTags
        title="Shottt"
        description="Shottt - Who sh👀ttt that ?"
        image="https://shottt.co/link-banner.png"
        url="https://shottt.co"
      />

      <div className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/* Colonne Gauche */}
          <div className="md:w-1/2 md:pr-8 mb-12 md:mb-0">
            <h1 className="text-5xl lg:text-6xl font-extrabold italic mb-6 text-center md:text-left tracking-tighter">
              sho<span className="tracking-[-0.1em]">ttt</span>
            </h1>
            <h2 className="text-xl lg:text-2xl text-gray-300 mb-4 text-center md:text-left">
              Who sh👀ttt that ?
            </h2>
            <p className="text-gray-400 mb-6 text-center md:text-left">
              Discover a new way to share your favorite moments with friends.
              Take photos anonymously and send them to your group chats.
              Download our app today and join a vibrant community.
            </p>
            <div className="flex flex-col items-center md:items-start">
              <Button
                href="https://testflight.apple.com/join/cd9pBjeJ"
                animate={true}
               
              >
                <span className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z" />
                  </svg>
                  Download on the App Store
                </span>
              </Button>
              <div className="mt-12">
                
                <QRCode
                  value="https://testflight.apple.com/join/cd9pBjeJ"
                  size={96}
                />
              </div>
            </div>
          </div>

          {/* Colonne Droite */}
          <div className="md:w-1/2 md:pl-8 flex justify-center items-center">
            <ImageCarousel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
