// src/pages/Invitation.tsx
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/Button";
import QRCode from "../components/QRCode";
import EmojiRain from "../components/EmojiRain";
import MetaTags from "../components/MetaTags";

const Invitation: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isIOS, setIsIOS] = useState<boolean>(false);

  useEffect(() => {
    const checkCode = () => {
      if (code && /^[a-zA-Z0-9]{8}$/.test(code)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    };
    checkCode();

    // Détecte si l'appareil est iOS
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent));
  }, [code]);

  if (!isValid) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white font-inter p-10">
        <h1 className="text-4xl font-bold mb-6">Invalid invitation code.</h1>
      </div>
    );
  }

  const inviteLink = `https://shottt.co/invite/${code}`;
  const appScheme = "shottt://";
  const appStoreLink = "https://apps.apple.com/app/idvotre-app-id";
  const playStoreLink = "https://play.google.com/store/apps/details?id=votre.app.id";

  const handleOpenApp = () => {
    if (isIOS) {
      window.location.href = appScheme;
      setTimeout(() => {
        window.location.href = appStoreLink;
      }, 500);
    } else {
      window.location.href = appScheme;
      setTimeout(() => {
        window.location.href = playStoreLink;
      }, 500);
    }
  };

  return (
    <>
      <MetaTags
        title={`Shottt | Invitation`}
        description="Join now and start sharing anonymous photos with your friends."
        image="https://shottt.co/link-banner.png"
        url={inviteLink}
      />
      <div className="relative min-h-screen bg-black text-white font-inter overflow-hidden">
        <div className="absolute inset-0 z-0">
          <EmojiRain emojisCount={5} fallDuration={15} />
        </div>
        <div className="relative z-10 flex flex-col items-center mt-12 sm:mt-0 sm:justify-center min-h-screen p-10">
          <h1 className="text-6xl md:text-6xl font-extrabold italic mb-4 md:mb-8 text-center tracking-tighter">
            Sho<span className="tracking-[-0.1em]">ttt</span>
          </h1>
          <h2 className="text-xl md:text-2xl font-bold mb-8 md:mb-12 text-center">
            Your crew is waiting for you!
          </h2>
          <Button onClick={handleOpenApp} animate={true} >
            Open{" "}
            <span className="font-extrabold italic tracking-tighter">
              Sho<span className="tracking-[-0.1em]">ttt</span>
            </span>
            <span className="ml-2">{"->"} </span>
          </Button>
          <div className="mb-8 md:mb-12 pt-12">
            <QRCode value={inviteLink} size={128} />
          </div>
         
        </div>
      </div>
    </>
  );
};

export default Invitation;
