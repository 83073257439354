import React from "react";
import { motion } from "framer-motion";

interface ButtonProps {
  children: React.ReactNode;
  href?: string;
  onClick?: () => void;
  animate?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ children, href, onClick, animate = false, className = "" }) => {
  const animationProps = animate
    ? {
        animate: {
          scale: [1, 1.05, 1],
        },
        transition: {
          duration: 2,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "reverse" as const,
        },
      }
    : {};

  const buttonContent = (
    <motion.span

      className={`text-lg  py-2 px-6  bg-white text-black font-bold rounded-full inline-block hover:bg-gray-200 transition-colors duration-200 ${className}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      {...animationProps}
    >
      {children}
    </motion.span>
  );

  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {buttonContent}
      </a>
    );
  }

  return (
    <button onClick={onClick}>
      {buttonContent}
    </button>
  );
};

export default Button;
