import React, { useState, useEffect, useRef } from "react";

const grained = (ele: string | HTMLElement, opt: any) => {
  const element = typeof ele === 'string' ? document.getElementById(ele.split('#')[1]) : ele;
  if (!element) return;

  const options = {
    animate: true,
    patternWidth: 100,
    patternHeight: 100,
    grainOpacity: 0.05,
    grainDensity: 1,
    grainWidth: 1,
    grainHeight: 1,
    ...opt
  };

  const noise = () => {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    if (!ctx) return '';

    canvas.width = options.patternWidth;
    canvas.height = options.patternHeight;

    for (let w = 0; w < options.patternWidth; w += options.grainDensity) {
      for (let h = 0; h < options.patternHeight; h += options.grainDensity) {
        const rgb = Math.floor(Math.random() * 256);
        ctx.fillStyle = `rgba(${rgb}, ${rgb}, ${rgb}, ${options.grainOpacity})`;
        ctx.fillRect(w, h, options.grainWidth, options.grainHeight);
      }
    }

    return canvas.toDataURL('image/png');
  };

  const generateStyles = () => {
    const styles = `
      position: absolute;
      content: "";
      height: 300%;
      width: 300%;
      left: -100%;
      top: -100%;
      background-image: url(${noise()});
      ${options.animate ? `
        animation: grained 8s steps(10) infinite;
        -webkit-animation: grained 8s steps(10) infinite;
      ` : ''}
    `;

    const keyframes = `
      @keyframes grained {
        0% { transform: translate(-10%, 10%); }
        10% { transform: translate(-25%, 0%); }
        20% { transform: translate(-30%, 10%); }
        30% { transform: translate(-30%, 30%); }
        40% { transform: translate(-20%, 20%); }
        50% { transform: translate(-15%, 10%); }
        60% { transform: translate(-20%, 20%); }
        70% { transform: translate(-5%, 20%); }
        80% { transform: translate(-25%, 5%); }
        90% { transform: translate(-30%, 25%); }
        100% { transform: translate(-10%, 10%); }
      }
    `;

    return { styles, keyframes };
  };

  const { styles, keyframes } = generateStyles();

  const grainElement = document.createElement('div');
  grainElement.style.cssText = styles;
  element.appendChild(grainElement);

  if (options.animate) {
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.innerHTML = keyframes;
    document.head.appendChild(styleElement);
  }
};

const ImageCarousel: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(1);
  const totalImages = 7;
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev % totalImages) + 1);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (carouselRef.current) {
      const options = {
        animate: true,
        patternWidth: 100,
        patternHeight: 100,
        grainOpacity: 0.05,
        grainDensity: 1,
        grainWidth: 1,
        grainHeight: 1
      };

      grained(carouselRef.current, options);
    }
  }, []);

  return (
    <div className="w-80 h-120 relative overflow-hidden rounded-3xl" ref={carouselRef}>
      {[...Array(totalImages)].map((_, index) => (
        <img
          key={index + 1}
          src={`/images/${index + 1}.png`}
          alt={`Image ${index + 1}`}
          className={`absolute top-0 left-0 w-full h-full object-cover object-center transition-opacity duration-500 ${
            currentImage === index + 1 ? "opacity-100" : "opacity-0"
          }`}
        />
      ))}
    </div>
  );
};

export default ImageCarousel;
