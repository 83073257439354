import React, { useEffect, useState } from 'react';

const emojis = ['📸',  '🙈','🤫', '👀','🤐'];

interface EmojiProps {
  emoji: string;
  style: React.CSSProperties;
}

const Emoji: React.FC<EmojiProps> = ({ emoji, style }) => (
  <div style={style}>
    {emoji}
  </div>
);

interface EmojiRainProps {
  emojisCount?: number;
  fallDuration?: number;
}

const EmojiRain: React.FC<EmojiRainProps> = ({ emojisCount = 5, fallDuration = 15 }) => {
  const [emojiElements, setEmojiElements] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newEmoji = emojis[Math.floor(Math.random() * emojis.length)];
      const actualDuration = fallDuration + Math.random() * 5; // Ajoute une variation à la durée
      const style: React.CSSProperties = {
        position: 'absolute',
        left: `${Math.random() * 100}vw`,
        top: '-50px', // Start slightly above the viewport
        fontSize: '2rem',
        animation: `fall ${actualDuration}s linear forwards`,
      };
      
      setEmojiElements(prev => [...prev.slice(-emojisCount + 1), <Emoji key={Date.now()} emoji={newEmoji} style={style} />]);
    }, 3000); 

    return () => clearInterval(interval);
  }, [emojisCount, fallDuration]);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      pointerEvents: 'none',
      overflow: 'hidden',
    }}>
      <style>
        {`
          @keyframes fall {
            0% {
              transform: translateY(0);
              opacity: 0.7;
            }
            100% {
              transform: translateY(calc(100vh + 50px));
              opacity: 0;
            }
          }
        `}
      </style>
      {emojiElements}
    </div>
  );
};

export default EmojiRain;
